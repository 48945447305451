var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "the-snapshot-bundle-dialog",
    {
      attrs: { bundleData: _vm.bundleData, defaultPanel: 1 },
      on: {
        "hook:mounted": function ($event) {
          _vm.hasMounted = true
        },
      },
    },
    [
      _c(
        "v-tooltip",
        {
          attrs: { right: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on }) {
                return [
                  _c(
                    "v-list-item",
                    _vm._g({}, on),
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "my-2 grading-tour",
                          attrs: { fab: "", color: "primary" },
                        },
                        [_c("v-icon", [_vm._v("grading")])],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [_c("span", [_vm._v("Assignment Grading")])]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }